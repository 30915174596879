<template>
  <div>
    <template v-if="!emailSent">
      <h1>
        Nulstil din adgangskode
      </h1>
      <p class="subheading">
        For at nulstille din adgangskode skal du indtaste den e-mailadresse, du er registreret med.
      </p>
      <form @submit.prevent="processForm">
        <BaseInput
          name="email"
          v-model="reset.email"
          label="E-mail*"
          placeholder="E-mail"
          :hasInput="!!v$.reset.email.$model"
          :isValid="!v$.reset.email.$invalid"
          :error="v$.reset.email.$error ? v$.reset.email.$errors[0].$message : ''"
          @blur="v$.reset.email.$touch()"
        />
        <button type="submit" class="button-primary">
          Nulstil
        </button>
      </form>
    </template>
    <div v-else-if="loading" class="flex-grow flex items-center justify-center">
      Loader ...
      <Spinner class="ml-3" />
    </div>
    <div v-else-if="success" class="card">
      Du vil snart modtage en e-mail med instruktioner om, hvordan du gendanner din konto
    </div>
    <div v-else class="card card-error">
      <h6 class="">
        Der er gået noget galt med at gendanne dit kodeord
      </h6>
      <p>
        Genindlæs siden og prøv igen. Hvis du får samme besked igen, så kontakt vores kundeservice på hackingalarm@skimsafe.dk
      </p>
    </div>
    <div class="flex justify-center">
      <router-link
        :to="{ name: 'Login' }"
        class=""
        :class="emailSent ? 'button-primary w-full' : 'textlink'"
      >
        Gå tilbage
      </router-link>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput'
import Spinner from '@/components/Spinner'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import ky from 'ky'

export default {
  name: 'Reset',
  components: {
    BaseInput,
    Spinner
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      reset: {
        email: ''
      },
      emailSent: false,
      loading: false,
      success: false
    }
  },
  validations () {
    return {
      reset: {
        email: {
          required: helpers.withMessage('E-mail er påkrævet', required),
          email: helpers.withMessage('E-mailadressen skal være gyldigt', email)
        }
      }
    }
  },
  methods: {
    async processForm () {
      this.v$.$touch()
      // console.log(this.v$.$invalid)
      if (!this.v$.$invalid) {
        this.emailSent = true
        this.loading = true
        try {
          const resetResponse = await ky.post('/api/reset', {
            json: {
              ...this.reset
            }
          }).json()
          // console.log(resetResponse)
          this.success = resetResponse.success
          this.loading = false
        } catch (error) {
          // console.log(error)
          this.loading = false
        }
      }
    }
  }
}
</script>
